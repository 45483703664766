<template>
  <VNavigationDrawer
    :color="color"
    :location="mobile ? 'bottom' : 'start'"
    :model-value="modelValue"
    :rail="rail && !mobile"
    style="min-height: 50%"
    :touchless="mobile"
    @update:model-value="emit('update:modelValue', $event)"
  >
    <slot />
  </VNavigationDrawer>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    modelValue: boolean
    color?: string
    rail?: boolean
    mobile?: boolean
  }>(),
  {
    color: 'white',
    rail: false,
    mobile: false,
  }
)

const emit = defineEmits<{
  'update:modelValue': [value: boolean]
}>()
</script>

<style scoped></style>
